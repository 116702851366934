// extracted by mini-css-extract-plugin
export var aboutMainCircleBlurBlue = "ReportsMain-module--aboutMainCircleBlurBlue--b5146";
export var aboutMainCircleBlurGreen = "ReportsMain-module--aboutMainCircleBlurGreen--e93de";
export var backgroundCircles = "ReportsMain-module--backgroundCircles--43170";
export var backgroundCirclesBlogArticles = "ReportsMain-module--backgroundCirclesBlogArticles--9ba3a";
export var blogsCircleBlueRight = "ReportsMain-module--blogsCircleBlueRight--ab426";
export var casePreviewImage = "ReportsMain-module--casePreviewImage--9defa";
export var contactOutlineCircle = "ReportsMain-module--contactOutlineCircle--551bd";
export var fadeInBottomRight = "ReportsMain-module--fadeInBottomRight--0b3de";
export var reportsColumnLeft = "ReportsMain-module--reportsColumnLeft--1552b";
export var reportsColumnRight = "ReportsMain-module--reportsColumnRight--7d45e";
export var reportsContainerWrapper = "ReportsMain-module--reportsContainerWrapper--bcb35";
export var reportsMainArticles = "ReportsMain-module--reportsMainArticles--bf544";
export var reportsMainContainer = "ReportsMain-module--reportsMainContainer--ae132";
export var reportsMainTags = "ReportsMain-module--reportsMainTags--dc2fa";
export var reportsRow = "ReportsMain-module--reportsRow--1518a";
export var reportsTag = "ReportsMain-module--reportsTag--42985";